import * as React from "react"
import { withSubtheme } from "@starberryComponentsMui";
import { get } from "lodash";

import ComponentLayout from "@myaccount/ComponentLayout"
import { useProfileState } from "@myaccountServices"
import formFields from "@myaccount/forms/Forms/notificationPreferences"
import ProfileForm from "@myaccount/forms/"
import { trackProfile } from "@myaccount/helper/eventTracking"
import {
    MYACCOUNT_LIGHT
} from "@myaccountUrls";
import defaults from './defaults';

const UserNotificationComponent = withSubtheme(props => {
    const {
        className,
        theme,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps
    } = props

    const { state, services } = useProfileState()
    const formStateType = state?.profile ?? {}
    const messageDialog = {message:state?.message, severity:state?.error ? 'error' : 'success'}
    const ref = React.useRef(null)

    // filter fields
    const filteredFormFields = formFields.filter((x) => {
        if (MYACCOUNT_LIGHT)
            return get(x, 'light', true)
        else
            return get(x, 'enterprise', true)
    })

    React.useEffect(() => {
        services.getUserProfile()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = event => {
        event.preventDefault()
        if (ref.current.checkValidity() !== false) {
            const formsdata = ref.current;
            const values = {}
            Object.keys(formsdata).map(key => {
                switch (formsdata[key].type) {
                    case "checkbox":
                        return values[formsdata[key].name] = formsdata[key].checked
                    default:
                        return (values[formsdata[key].name] = formsdata[key].value)
                }
            })
            services.updateProfile({
                ...values, id: state.user.uid,
                enableLoading: false,
                successMessage:'Notification preferences updated!',
                trackData: () => {
                    trackProfile({
                        eventLabel: 'Notification preferences'
                    })
                }
            })

        } else {
            services.updateMessage({message:"Please fill required fields", error:true});
            // @todo We could display the global from error message. But anyhow field validations are done field itself
        }
    }

    return (
        <ComponentLayout
            className={className}
            theme={theme}
            title={title}
            moreLabel={moreLabel}
            moreLinkUrl={moreLinkUrl}
            titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
        >
            <ProfileForm
                formFields={filteredFormFields}
                services={services}
                handleSubmit={handleSubmit}
                formStateType={formStateType}
                messageDialog={messageDialog}
                ref={ref}
            />
        </ComponentLayout>
    )
}, "profileMyAccountNotificationPreferences", defaults)

export default UserNotificationComponent
