export default {
    "props": {
        "profileChangePasswordProps": {

        },
    },
    "sx": {
        "& .globalForm-actionContainer": {
            "& .globalForm-formButton": {
                "width": "50%",
            },
        }
    }
}