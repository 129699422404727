import React from 'react';
import { withSubtheme } from "@starberryComponentsMui"
import ActionModalDefault from '@myaccount/modals/default';
import PropertyPreferenceDialog from './dialogContent';
import CircularProgress from '@myaccount/progress/CircularProgress'
import { useProfileState } from "@myaccountServices";
import defaults from "./dialogDefaults"

const PropertyPreferences = withSubtheme(props => {
    // popup
    const { conversationalFormEnabled, forceRegistration } = props;

    const [open, setOpen] = React.useState(true);
    const { state } = useProfileState()
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = (value) => {
        value.preventDefault();
        setOpen(false);
    };

    let dialogContent = '';
    if (state.loading) {
        dialogContent = <CircularProgress />
    } else {
        dialogContent = (
            <PropertyPreferenceDialog
                conversationalFormEnabled={conversationalFormEnabled}
                handleClose={handleClose}
                modal={true}
            />
        );
    }
    // React.useEffect(() => {
    //     // services.getUserProfile()
    // }, []) // eslint-disable-line react-hooks/exhaustive-deps

    let dialogAddtionalProps = {}
    if ((state.preference_success && !state.error) || !forceRegistration)
        dialogAddtionalProps = { handleClose }

    return (
        <>
            {state.loading ? (
                <ActionModalDefault
                    open={open}
                    children={<CircularProgress />}
                    loading={true}
                />
            ) : (
                <ActionModalDefault
                    open={open}
                    handleClickOpen={handleClickOpen}
                    children={dialogContent}
                    title={`Register your property preference`}
                    maxDialogWidth={`sm`}
                    {...dialogAddtionalProps}
                />
            )}
        </>
    )
}, "dialogPreference", defaults)

export default PropertyPreferences
