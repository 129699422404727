import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import PropertyGrid from '@components/PropertyGrid';
import ComponentLayout from '@myaccount/ComponentLayout';

const LatestProperties = withSubtheme((props) => {
    const {
        className,
        properties,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps,
        ...rest
    } = props

    return(
        <ComponentLayout
            className={className}
            title={title}
            moreLabel={moreLabel}
            moreLinkUrl={moreLinkUrl}
            titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
        >
            <PropertyGrid
                properties={properties}
                {...rest}
            />
        </ComponentLayout>
    )
}, 'latestProperties')

export default LatestProperties
    