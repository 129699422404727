import * as React from 'react';
import UserChangePasswordComponent from './password';
import { ProfileStoreProvider } from "@myaccountServices"

const UserChangePassword = props => {
    return (
        <ProfileStoreProvider>
            <UserChangePasswordComponent props={props} />
        </ProfileStoreProvider>
    )
}

export default UserChangePassword;
