import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';

import ComponentLayout from '@myaccount/ComponentLayout';
import { useProfileState, useAuthState } from "@myaccountServices"
import formFields from "@myaccount/forms/Forms/changePass"
import PasswordResetForm from "@myaccount/forms/"
import { trackProfile } from "@myaccount/helper/eventTracking"

import defaults from './defaults';

const UserChangePasswordComponent = withSubtheme((props) => {
    const {
        className,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps,
        profileChangePasswordProps,
        // ...rest
    } = props;

    const { state, services } = useProfileState();
    const { state:authState } = useAuthState();
    const ref = React.useRef(null)
    const messageDialog = {message:state?.message, severity:state?.error ? 'error' : 'success'}
    const formStateType = state?.fieldValues ?? {}

    const handleSubmit = (event) => {
        event.preventDefault();
        if (ref.current.checkValidity() !== false) {
            const formsdata = ref.current;
            const values = {}
            Object.keys(formsdata).map(key => {
                if (formsdata[key].value)
                    return (
                        values[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
                    )
                else
                    return false;
            })
            if (values?.password && values?.confirmPassword) {
                if (values.password === values.confirmPassword) {
                    services.updatePassword({
                        ...values, id:authState.user.uid,
                        trackData: () => {
                            trackProfile({
                                eventLabel: 'Update Password'
                            })
                        }
                    });
                } else {
                    services.updateMessage({message:"Passwords do not match", error:true, fieldValues:values});
                }
            }
        } else {
            services.updateMessage({message:"Please fill required fields", error:true});
        }
    };

    return(
        <ComponentLayout
            className={className}
            title={title}
            moreLabel={moreLabel}
            moreLinkUrl={moreLinkUrl}
            titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
            {...profileChangePasswordProps}
        >
            <PasswordResetForm
                ref={ref}
                formFields={formFields}
                services={services}
                handleSubmit={handleSubmit}
                formStateType={formStateType}
                messageDialog={messageDialog}
            />
        </ComponentLayout>
    )
}, 'profileMyAccountChangePassword', defaults)

export default UserChangePasswordComponent;
