import React from 'react';
import _ from "lodash";
import { withSubtheme } from '@starberryComponentsMui';
import Flexbox from '@mui/material/Grid';
import Welcome from '../user/welcome';
import Tabs from '../tabs';
import Container from '@mui/material/Container';
import {
    // UserObjectStoreProvider,
    useMyaccountState,
    useAuthState
} from "@myaccountServices"
import { MYACCOUNT_MYPROPERTY, MYACCOUNT_MYPROPERTY_URLS } from "@myaccountUrls";
import { setValuesToUrl } from '@myaccount/helper/urls';

import LatestProperties from '@myaccount/property/latestProperties';
import SingleProperty from '@myaccount/property/singleProperty';
import ValuationModule from '@myaccount/simpleModule/valuation';
import ContactModuleComp from "@myaccount/pages/my-property/common/contactModule";
import PendingItems from '@myaccount/pendingItems';
import PendingActions from '@myaccount/pendingActions';
import RecentActivities from '@myaccount/recentActivities';
import CircularProgress from "@myaccount/progress/CircularProgress";
import ModuleRender from "@myaccount/modules/moduleRender";

import _properties from '@myaccountSampleData/properties.yaml';

// const properties = _properties;
const MyProperties = React.memo(({mypropertiesData, state}) => {

    const data = [];
    if (mypropertiesData) {

        Object.entries(mypropertiesData).map(([search_type, pTypeData]) => {
            Object.entries(pTypeData).map(([key, value]) => {
                let propertyDetail = value?.property_detail || {}
                let link = setValuesToUrl(MYACCOUNT_MYPROPERTY_URLS, search_type, propertyDetail?.crm_id)
                data.push({...propertyDetail, link:link});
            })
        })
    }

    const latestItems =  _.sampleSize(data, 3);
    const promotedItem = _.sampleSize(data, 1);
    const properties = latestItems || _properties;
    const viewMoreLink = (properties.length > 3) ? 'View All' : '';


    if (state.loading) {
        return <CircularProgress />
    }

    return (
        <>
            <SingleProperty
                properties={promotedItem}
            />
            <LatestProperties
                properties={properties}
                title="My Properties"
                moreLabel={viewMoreLink}
                moreLinkUrl={MYACCOUNT_MYPROPERTY}
                titleLinkMoreTooltipProps={{"title": "View more"}}
            />
        </>
    )
})

const ActionComp = React.memo(() => {
    const tabs = [
        {
            "id": "tab1",
            "name": "Pending Actions",
            "content": <PendingActions />,
        },
        {
            "id": "tab2",
            "name": "Recent Activity",
            "content": <RecentActivities />,
        }
    ]
    return(
        <Tabs
            data={tabs}
        />
    )
})


const MyAccountIndex = withSubtheme(props => {
    const {
        theme
    } = props;

    const { state, services } = useMyaccountState();
    const { services:authServices } = useAuthState();
    const staticModules = theme.getProp("staticModules");

    React.useEffect(() => {
        services.getMyNegotiatorData()
        services.getMypropertiesData()
        authServices.updateState({pageTitle:`Dashboard`, pageRightCustomComponent: null, pageRightDrawer: null, pageLeftIcon: false});
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const negotiator_info = state?.mynegotiator || {}
    const mypropertiesData = state?.mypropertiesData || {}

    return (
        <Container maxWidth="xl">
            <Flexbox container spacing={1}>
                <Flexbox item xs={12}>
                    <Welcome />
                </Flexbox>
                <Flexbox item xs={12}>
                    <Flexbox container spacing={1}>
                        <Flexbox item xs={12} lg={8}>
                            <ActionComp />
                            <PendingItems
                                title="Sales Progress"
                                theme={theme}
                                data={props?.sales_progression || []}
                            />
                        </Flexbox>
                        <Flexbox item xs={12} lg={4}>
                            <Flexbox container spacing={1}>
                                <Flexbox item xs={12} sm={6} md={12}>
                                    <MyProperties mypropertiesData={mypropertiesData} state={state} />
                                </Flexbox>
                                <Flexbox item xs={12} sm={6} md={12}>
                                    <ValuationModule />
                                    <ContactModuleComp
                                        moduleTitle="Contact your agent"
                                        data={negotiator_info}
                                    />
                                </Flexbox>
                            </Flexbox>
                        </Flexbox>
                    </Flexbox>
                </Flexbox>
                {staticModules && staticModules.length &&
                    <Flexbox item xs={12}>
                        <ModuleRender staticModules={staticModules} />
                    </Flexbox>
                }
            </Flexbox>
        </Container>
    );
}, 'homePage')

export default React.memo(MyAccountIndex);
