import * as React from "react"
import { ActionStoreProvider, useActionState, useAuthState } from "@myaccountServices"
import Actions from "@myaccount/actions"
import NoActions from "@myaccount/progress/NoActions"
import Alert from "@myaccount/alert"
import ActionsSkeleton from "@myaccount/actions/actionsSkeleton"

const PendingActionsInit = (props) => {
    const {
        noActionTitle,
        noActionMessage,
        noActionIcon,
        ...rest
    } = props

    const { state, services } = useActionState()
    const { services:authServices } = useAuthState();

    const reloadDataFun = React.useCallback(() => {
        authServices.getPendingActionsCount();
        fetchData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const fetchData = () => {
        services.getPendingActions()
    }

    React.useEffect(() => {
        fetchData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const data = (rest?.data && rest?.data.length) || state?.pendingActionData || []

    if (state.loading_actions && !data.length) {
        return <ActionsSkeleton sx={{ my: 2 }} />;
    }

    if (state.error) {
        return <Alert type={`alert`} message={state.message} />
    }

    if (!state.loading_actions && !data.length) {
        return (
            <NoActions
                title={noActionTitle ? noActionTitle : "There are no pending actions"}
                // message={noActionMessage ? noActionMessage : "At the moment all the actions are closed..."}
                icon={noActionIcon ? noActionIcon : "noPendingActions"}
            />
        )
    }

    return (
        <>
            <Actions reloadDataFun={reloadDataFun} data={data} />
        </>
    )
}

const PendingActions = React.memo((props) => {
    return (
        <ActionStoreProvider>
            <PendingActionsInit {...props} />
        </ActionStoreProvider>
    )
})

export default PendingActions
