export default {
    "props": {
    },
    "sx": {
        "& .globalForm-actionContainer": {
            "& .globalForm-formButton": {
                "width": "50%",
            },
        }   
    }
}