import React from 'react';
import MyAccountIndex from '@myaccount/containers/index';
import { MyaccountStoreProvider } from "@myaccountServices";
import GlobalLayout from "@myaccount/GlobalLayout";

const MyAccountIndex2Page = () => {
    return (
        <GlobalLayout>
            <MyaccountStoreProvider>
                <MyAccountIndex />
            </MyaccountStoreProvider>
        </GlobalLayout>
    );
}

export default React.memo(MyAccountIndex2Page);
