import React, { useEffect } from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import {trackSignUp } from "@myaccount/helper/eventTracking";
// MUI imports
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import { Link } from 'gatsby';
// Icons
import VpnKeyIcon from '@mui/icons-material/VpnKey';
// Components
import StyledDialogTitle from './title';
import StyledDialogContent from './contentSignup';
import Logo from '@myaccount/Logo/LogoLogin';

import defaults from './defaults';

// My Account
import { useAuthState } from "@myaccountServices"
import { loginRedirect } from '@myaccount/user/authcallback'

const SignUpModal = withSubtheme(props => {
    const {
        theme,
        className,
        dialogTitleProps,
        dialogContentProps,
        login,
        signup,
        logoType
    } = props

    const {
        signupBtnType,
        signupBtnVariant,
        fullScreen,
        dialogScroll,
        data,
        defaultCountry,
        usePhoneField
    } = signup

    const {
        setOpenDefault,
        triggerText,
        signUpTitle,
        signUpSubtitle,
        showSocialLogin,
        showTerms,
        termsText,
        termsLinkProps,
        termsLink,
        termsLabel,
        privacyLink,
        privacyLabel,
        formActionLabel,
    } = data

    const {
        termsSiteLink,
        privacySiteLink
    } = theme;

    const { state, services } = useAuthState();

    let signupSubtitleSub = null;
    if (undefined !== login.data.loginUrl && undefined !== signUpSubtitle)
        signupSubtitleSub = <>{signUpSubtitle} <Link to={login.data.loginUrl} color="inherit">{login.data.loginUrlLabel}</Link></>;

    // logic for opening/closing dialog
    const [open, setOpen] = React.useState(setOpenDefault);
    const fullScreenBreakpoint = fullScreen ? useMediaQuery(theme.breakpoints.down(fullScreen)) : false;
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        if (true === setOpenDefault) {
            return null
        } else {
            setOpen(false);
        }
    };
    // Once user get logined close the dialog
    useEffect(() => {
        if (services.isAuthenticated()) {
            setOpen(false);

            trackSignUp()

            loginRedirect(services, state);
        }

    }, [state.user]) // eslint-disable-line react-hooks/exhaustive-deps

    // Reset error message
    useEffect(() => {
        services.resetMessage();
    }, []);

    let loginTypeOut = null;
    if (!setOpenDefault) { // if we want the modal to be always opened we don't need a trigger button
        switch (signupBtnType) {
            case "btn":
                loginTypeOut = <Button variant={signupBtnVariant} color="primary" onClick={handleClickOpen}>{triggerText}</Button>
                break;
            case "btnIcon":
                loginTypeOut = <IconButton aria-label="signup" color="inherit" variant={signupBtnVariant} onClick={handleClickOpen}><VpnKeyIcon /></IconButton>
                break;
            case "btnTextIcon":
                loginTypeOut = (<Button
                                    variant={signupBtnVariant}
                                    color="primary"
                                    startIcon={<VpnKeyIcon />}
                                    onClick={handleClickOpen}
                                >
                                    {triggerText}
                                </Button>)
                break;
            default:
                loginTypeOut = <Button color="primary" variant={signupBtnVariant} onClick={handleClickOpen}>{triggerText}</Button>
                break;
        }
    }

    return (
        <>
            {loginTypeOut}
            <Dialog
                fullScreen={fullScreenBreakpoint}
                open={open}
                onClose={handleClose}
                scroll={dialogScroll}
                aria-labelledby="signup-dialog"
                classes={{root: className, paper: "paper-dialog"}}
                BackdropProps={{ classes: {root: "backDrop"} }}
            >
                <Logo type1={logoType || 'logo'} />
                <StyledDialogTitle
                    id="signup-responsive-dialog-title"
                    title={signUpTitle}
                    dialogTitleProps={dialogTitleProps}
                />
                <StyledDialogContent
                    subtitle={signupSubtitleSub}
                    showSocialLogin={showSocialLogin}
                    showTerms={showTerms}
                    termsText={termsText}
                    termsLinkProps={termsLinkProps}
                    termsLink={termsSiteLink || termsLink}
                    termsLabel={termsLabel}
                    privacyLink={privacySiteLink || privacyLink}
                    privacyLabel={privacyLabel}
                    handleClose={handleClose}
                    defaultCountry={defaultCountry}
                    usePhoneField={usePhoneField}
                    state={state}
                    services={services}
                    formActionLabel={formActionLabel}
                    dialogContentProps={dialogContentProps}
                />
            </Dialog>
        </>
    );
}, 'loginSignup', defaults)

export default React.memo(SignUpModal);
