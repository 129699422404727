import React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import Grid from '@mui/material/Grid';
import ResetPassEmail from "@components/MyAccount/modals/ResetPassEmail";

const MyAccountResetPassEmail = withSubtheme((props) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <ResetPassEmail onClose={() => void 0} />
            </Grid>
        </Grid>
    );
}, 'resetPassEmailPage')

export default React.memo(MyAccountResetPassEmail);
