import React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import Flexbox from '@mui/material/Grid';
import CreatePass from "@components/MyAccount/modals/CreatePass";

const MyAccountResetPass = withSubtheme((props) => {
    return (
        <Flexbox container>
            <Flexbox item xs={12}>
                <CreatePass onClose={() => void 0} />
            </Flexbox>
        </Flexbox>
    );
}, 'resetPassPage')

export default React.memo(MyAccountResetPass);
