import React from 'react';
import MyAccountInbox from '@myaccount/containers/inbox';
import { MyaccountStoreProvider } from "@myaccountServices"
import GlobalLayout from "@myaccount/GlobalLayout"

const MyAccountInboxPage = () => {
    return (
        <GlobalLayout>
            <MyaccountStoreProvider>
                <MyAccountInbox />
            </MyaccountStoreProvider>
        </GlobalLayout>
    );
}

export default React.memo(MyAccountInboxPage);
