import React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import Grid from '@mui/material/Grid';
import ResetPass from "@components/MyAccount/modals/ResetPass";

const MyAccountResetPass = withSubtheme((props) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <ResetPass onClose={() => void 0} />
            </Grid>
        </Grid>
    );
}, 'resetPassPage')

export default React.memo(MyAccountResetPass);
