import * as React from "react"
import { withSubtheme } from "@starberryComponentsMui"
// import Button from "@mui/material/Button"

import ComponentLayout from "@myaccount/ComponentLayout"
import { useProfileState, getUser } from "@myaccountServices"
import profileFormFields from "@myaccount/forms/Forms/profile"
import ProfileForm from "@myaccount/forms/"
import { collectFormValues, validateFormFields } from "@myaccount/forms/utils";
import { MYACCOUNT_LIGHT } from "@myaccountUrls";
import CircularProgress from "@myaccount/progress/CircularProgress";
import { trackProfile } from "@myaccount/helper/eventTracking"

import defaults from "./defaults";

import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

const UserProfileComponent = withSubtheme(props => {
    const {
        theme,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps,
        className
    } = props

    const { state, services } = useProfileState()
    let customFields = [...profileFormFields];

    const [formFields, setFormFields ] = React.useState(customFields);

    const messageDialog = {message:state?.message, severity:state?.error ? 'error' : 'success'}
    const ref = React.useRef(null)

    const userDetails = useSelector(state => state.profile);

    const formStateType = userDetails?.profile ?? {}

    React.useEffect(() => {
        if(isEmpty(userDetails.profile))
            services.getUserProfile()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = event => {
        event.preventDefault()
        const isBrowser = typeof window !== "undefined"
        if (isBrowser) {
            window.scrollTo(0, 0)
        }
        const formWithValue = validateFormFields(ref, customFields)
        setFormFields(formWithValue);

        if (ref.current.checkValidity() !== false && validateFormFields(ref, customFields, true) === false) {
            const params = {
                id: getUser().uid,
                // successMessage: "Profile Successfully",
            }
            let values = collectFormValues(ref, params)
            if (!MYACCOUNT_LIGHT) {
                values = {...values, contact_id:state?.user?.contact_id}
            }
            services.updateProfile({
                ...values,
                trackData: () => {
                    trackProfile({
                        eventLabel: 'Profile'
                    })
                }
            })
        } else {
            services.updateMessage({message:"Please fill required fields", error:true});
            // @todo We could display the global from error message. But anyhow field validations are done field itself
        }
    }

    if (state.loading) {
        return <CircularProgress />
    }

    return (
        <ComponentLayout
            theme={theme}
            title={title}
            moreLabel={moreLabel}
            moreLinkUrl={moreLinkUrl}
            titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
            className={className}
        >
            <ProfileForm
                formFields={formFields}
                services={services}
                handleSubmit={handleSubmit}
                formStateType={formStateType}
                messageDialog={messageDialog}
                ref={ref}
            />
        </ComponentLayout>
    )
}, "profileMyAccountForm", defaults)

export default UserProfileComponent
