import * as React from 'react';
import { ProfileStoreProvider } from "@myaccountServices"
import UserNotificationPreferences from './notificationPreference';

const NotificationPreferences = props => {
    return (
        <ProfileStoreProvider>
            <UserNotificationPreferences props={props} />
        </ProfileStoreProvider>
    )
}

export default NotificationPreferences;
