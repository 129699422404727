import React, { useEffect } from 'react';
import { withSubtheme } from '@starberryComponentsMui';
// MUI imports
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
// Icons
import VpnKeyIcon from '@mui/icons-material/VpnKey';
// Components
import StyledDialogTitle from './title';
import StyledDialogContent from './contentResetPassEmail';
import Logo from '@myaccount/Logo/LogoLogin';

import defaults from './defaults';

// My Account
import { useAuthState } from "@myaccountServices"
import { loginRedirect } from '@myaccount/user/authcallback'

const ResetPassEmail = withSubtheme(props => {
    const {
        theme,
        className,
        dialogTitleProps,
        dialogContentProps,
        resetPassEmail,
        logoType
    } = props

    const {
        loginBtnType,
        loginBtnVariant,
        fullScreen,
        data
    } = resetPassEmail

    const {
        setOpenDefault,
        triggerText,
        loginTitle,
        loginSubtitle,
        showSocialLogin,
        showTerms,
        termsText,
        termsLinkProps,
        termsLink,
        termsLabel,
        privacyLink,
        privacyLabel,
        formActionLabel,
    } = data

    const {
        termsSiteLink,
        privacySiteLink
    } = theme;

    const { state, services } = useAuthState();

    let loginSubtitleSub = null;
    if (undefined !== loginSubtitle)
        loginSubtitleSub = <>{loginSubtitle}</>;

    // logic for opening/closing dialog
    const [open, setOpen] = React.useState(setOpenDefault);
    const fullScreenBreakpoint = fullScreen ? useMediaQuery(theme.breakpoints.down(fullScreen)) : false;
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        if (true === setOpenDefault) {
            return null
        } else {
            setOpen(false);
        }
    };

    // Once user get logined close the dialog
    useEffect(() => {
        if (services.isAuthenticated()) {
            setOpen(false);
            loginRedirect(services, state);
        }
    }, [state.user]) // eslint-disable-line react-hooks/exhaustive-deps

    // Reset error message
    useEffect(() => {
        services.resetMessage();
    }, []);

    let loginTypeOut = null;
    if (!setOpenDefault) { // if we want the modal to be always opened we don't need a trigger button
        switch (loginBtnType) {
            case "btn":
                loginTypeOut = <Button variant={loginBtnVariant} color="primary" onClick={handleClickOpen}>{triggerText}</Button>
                break;
            case "btnIcon":
                loginTypeOut = <IconButton aria-label="login" color="inherit" variant={loginBtnVariant} onClick={handleClickOpen}><VpnKeyIcon /></IconButton>
                break;
            case "btnTextIcon":
                loginTypeOut = (<Button
                                    variant={loginBtnVariant}
                                    color="primary"
                                    startIcon={<VpnKeyIcon />}
                                    onClick={handleClickOpen}
                                >
                                    {triggerText}
                                </Button>)
                break;
            default:
                loginTypeOut = <Button color="primary" variant={loginBtnVariant} onClick={handleClickOpen}>{triggerText}</Button>
                break;
        }
    }

    return (
        <>
            {loginTypeOut}
            <Dialog
                fullScreen={fullScreenBreakpoint}
                open={open}
                onClose={handleClose}
                aria-labelledby="reset-email-responsive-dialog-title"
                classes={{root: className, paper: "paper-dialog"}}
                BackdropProps={{ classes: {root: "backDrop"} }}
            >
                <Logo type1={logoType || 'logo'} />
                <StyledDialogTitle
                    id="reset-email-responsive-dialog-title"
                    title={loginTitle}
                    theme={theme}
                    dialogTitleProps={dialogTitleProps}
                />
                <StyledDialogContent
                    subtitle={loginSubtitleSub}
                    showSocialLogin={showSocialLogin}
                    showTerms={showTerms}
                    termsText={termsText}
                    termsLinkProps={termsLinkProps}
                    termsLink={termsSiteLink || termsLink}
                    termsLabel={termsLabel}
                    privacyLink={privacySiteLink || privacyLink}
                    privacyLabel={privacyLabel}
                    state={state}
                    services={services}
                    formActionLabel={formActionLabel}
                    dialogContentProps={dialogContentProps}
                />
            </Dialog>
        </>
    );
}, 'loginSignup', defaults)

export default React.memo(ResetPassEmail);
