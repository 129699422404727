import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';

// import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';

import defaults from './defaults';

const UserAvatar = withSubtheme((props) => {
    const {
        className,
        alt,
        src,
        // showSmallAvatar = true,
        // ...rest
    } = props;

    return(
        <Badge
            overlap="circular"
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            badgeContent={``/*showSmallAvatar &&
                <Avatar classes={{root: "userAvatar-smallAvatar"}} alt={alt} src={src}>
                    <CameraAltIcon classes={{root: "userAvatar-smallAvatarIcon"}} />
                </Avatar>
            */}
            classes={{root: className}}
        >
            <Avatar classes={{root: "userAvatar-avatar"}} alt={alt} src={src} />
        </Badge>
    )
}, 'userAvatar', defaults)

export default UserAvatar
