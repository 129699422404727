import React from 'react';
import MyAccountIndex from '@myaccount/containers/index2';
import { MyaccountStoreProvider } from "@myaccountServices"
import GlobalLayout from "@myaccount/GlobalLayout"

const MyAccountIndexPage = () => {
    return (
        <GlobalLayout>
            <MyaccountStoreProvider>
                <MyAccountIndex />
            </MyaccountStoreProvider>
        </GlobalLayout>
    );
}

export default React.memo(MyAccountIndexPage);
