import React from 'react';
import MyAccountCalendar from '@myaccount/containers/calendar';
import { MyaccountStoreProvider } from "@myaccountServices"
import GlobalLayout from "@myaccount/GlobalLayout"

const MyAccountCalendarPage = () => {
    return (
        <GlobalLayout>
            <MyaccountStoreProvider>
                <MyAccountCalendar />
            </MyaccountStoreProvider>
        </GlobalLayout>
    );
}

export default React.memo(MyAccountCalendarPage);
