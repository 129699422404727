import * as React from 'react';

import UserProfileComponent from './profile';
import { ProfileStoreProvider } from "@myaccountServices";

// import * as testMyAcc from '@myaccountServices';


const UserProfile = (props) => {
    return (
        <ProfileStoreProvider>
            <UserProfileComponent props={props} />
        </ProfileStoreProvider>
    )
}

export default React.memo(UserProfile);
